<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row gutter-b">
      <div class="col-xxl-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Form Admin</h6>
          </template>
          <div class="mb-4">
            <h6 class="mb-1">Name <span class="text-danger">*</span></h6>
            <b-form-input
              v-model="admin.name"
              placeholder="Name"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Email <span class="text-danger">*</span></h6>
            <b-form-input
              v-model="admin.email"
              placeholder="Email address"
              type="email"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Password <span class="text-danger">*</span></h6>
            <b-form-input
              v-model="admin.password"
              placeholder="Password"
              type="password"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Confirmation Password</h6>
            <b-form-input
              v-model="admin.confirm_password"
              placeholder="Password"
              type="password"
            ></b-form-input>
          </div>
          <!-- <div class="mb-4">
            <h6 class="mb-1">Phone Number</h6>
            <b-form-input
              v-model="admin.phone_number"
              placeholder="Phone number"
            ></b-form-input>
          </div> -->
          <!-- <div class="mb-4">
            <h6 class="mb-1">Birtdate</h6>
            <b-form-input
              v-model="admin.dob"
              placeholder="Birtdate"
              type="date"
            ></b-form-input>
          </div> -->
          <!-- <div class="mb-4">
            <h6>Photo</h6>
            <b-form-file
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="handleFile"
            ></b-form-file>
          </div> -->
          <div class="mb-4">
            <h6>Role <span class="text-danger">*</span></h6>
            <b-form-select
              v-model="admin.role_id"
              :options="roles"
            ></b-form-select>
          </div>
          <div class="mb-4" v-if="optionCustomerBranch.length">
            <h6>Customer Branch</h6>
            <b-form-group>
              <multiselect
                v-model="selectedBranchId"
                :options="optionCustomerBranch"
                :multiple="true"
                label="text"
                value="value"
                track-by="value"
                @select="onSelect"
                @remove="onRemove"
              ></multiselect>
            </b-form-group>
          </div>

          <template v-slot:footer>
            <b-button
              variant="primary"
              @click="createAdmin"
              :disabled="loading"
              v-if="$route.name === 'admin-create'"
            >
              {{ loading ? "Loading..." : "Submit" }}
            </b-button>
            <b-button
              variant="warning"
              @click="updateAdmin"
              :disabled="loading"
              v-else
            >
              {{ loading ? "Loading..." : "Update" }}
            </b-button>
          </template>
        </b-card>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { dateToISOString } from "@/utils/helper";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateAdmin",
  data() {
    return {
      admin: {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        // phone_number: null,
        // dob: null,
        // photo: null,
        role_id: "",
        customer_branch_ids: [],
      },
      photo: null,
      loading: false,
      roles: [],
      optionCustomerBranch: [],
      selected: 1,
      value: null,
      options: ["list", "of", "options"],
      optionTags: [],
      selectedBranchId: [],
    };
  },
  components: {
    Multiselect,
  },
  mounted() {
    this.getRoles();
    this.customerBranch();

    let lastBreadcrumb = "";
    if (this.$route.name === "admin-create") {
      lastBreadcrumb = { title: "Create admin" };
    } else {
      lastBreadcrumb = { title: "Edit admin" };
      this.initData();
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Admin", route: "/admin" },
      lastBreadcrumb,
    ]);
  },
  methods: {
    dateToISOString,
    createAdmin() {
      let formData = new FormData();
      formData.append("name", this.admin.name);
      formData.append("email", this.admin.email);
      // formData.append("phone_number", this.admin.phone_number);
      // formData.append("dob", this.admin.dob);
      formData.append("password", this.admin.password);
      formData.append("role_id", this.admin.role_id);
      // formData.append("photo", this.photo);
      if (this.admin.customer_branch_ids.length) {
        this.admin.customer_branch_ids.forEach((id) => {
          formData.append("[]customer_branch_ids", id);
        });
      }

      if (this.admin.password != this.admin.confirm_password) {
        this.$bvModal.msgBoxOk(`Confirmation password is not match`, {
          title: "Error",
          okTitle: "Close",
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      } else {
        this.loading = true;
        this.$axios
          .post(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/admin/user`,
            formData
          )
          .then(() => {
            this.loading = false;
            this.$router.go(-1);
          })
          .catch((error) => {
            this.loading = false;
            this.$bvModal.msgBoxOk(`${error.response.data.message}`, {
              title: "Error",
              okTitle: "Close",
              size: "sm",
              buttonSize: "sm",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          });
      }
    },
    handleFile(e) {
      const file = e.target.files[0];
      this.photo = file;
    },
    getRoles() {
      this.$axios
        .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/roles`)
        .then(({ data }) => {
          this.roles = [];
          this.roles.push({
            value: "",
            text: "Select Role",
          });
          data.map((item) => {
            this.roles.push({
              value: item.id,
              text: item.name,
            });
          });
        });
    },
    customerBranch() {
      this.$axios
        .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/customer-branches`)
        .then(({ data }) => {
          this.optionCustomerBranch = [];
          data.list.map((item) => {
            this.optionCustomerBranch.push({
              value: item.id,
              text: item.name,
            });
          });
        });
    },
    initData() {
      this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/admin/detail/${this.$route.params.id}`
        )
        .then(({ data }) => {
          this.admin = data;
          this.admin.role_id = data.role.id;
          this.admin.dob = this.dateToISOString(this.admin.dob);
        });
    },
    updateAdmin() {
      // eslint-disable-next-line
      console.log("update admin");
    },
    onSelect(newTag) {
      this.admin.customer_branch_ids.push(newTag.value);
    },
    onRemove(removeTag) {
      const index = this.admin.customer_branch_ids.findIndex(
        (item) => item === removeTag.value
      );
      this.admin.customer_branch_ids.splice(index, 1);
    },
  },
};
</script>
<style lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
